<template>

  <v-dialog v-model="showModal" persistent max-width="800" scrollable>
    <v-card>
      <v-card-title class="headline">
        <template v-if="sendRegistrationPhase === 'sending'">
          Registratie wordt verstuurd
        </template>
        <template v-if="sendRegistrationPhase === 'server-processing'">
          Registratie wordt verwerkt op de server
        </template>
      </v-card-title>
      <v-card-text class="pt-4">
        <template v-if="sendRegistrationPhase === 'sending'">
          <v-progress-linear
              :value="sendRegistrationProgress"
              color="primary"
          ></v-progress-linear>
          <p class="text-caption text--secondary mt-5">Moment geduld alstublieft. Dit kan een tijdje duren wanneer u veel of grote afbeeldingen toegevoegd heeft.</p>
        </template>
        <template v-if="sendRegistrationPhase === 'server-processing'">
          <div class="text-center">
            <v-progress-circular
                :size="50"
                indeterminate
                color="primary"
            ></v-progress-circular>
          </div>
          <p class="text-caption text--secondary mt-5">Moment geduld alstublieft. Dit kan een tijdje duren wanneer u veel of grote afbeeldingen toegevoegd heeft.</p>
        </template>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>

</template>
<script>
export default {
  name: "FormFillSavingModal",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    sendRegistrationProgress: {
      type: Number,
      required: true,
    },
    sendRegistrationPhase: {
      type: String,
      required: true,
    },
  },
  methods: {},
}
</script>

<style lang="scss">
// Use the compact style
@import '@/styles/formulate-compact.scss';

// Colors
$green: var(--v-success-base);
$primary: var(--v-primary-base);
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 56px;
  height: 56px;
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $primary;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $primary;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $primary;
  }
}
</style>
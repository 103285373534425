import _ from "lodash";
import eformHelpers from "@/mixins/eformHelpers";

export default {
    mixins: [
        eformHelpers,
    ],
    mounted() {

    },
    data() {
        return {
            $_tokens: [],
        }
    },
    computed: {
        conditionalSchema() {
            let conditionalSchema = this.schema;
            for (let index in this.rules) {
                conditionalSchema = this.parseRule(this.rules[index], conditionalSchema);
            }
            return conditionalSchema;
        },
        tokensFromSchema() {
            return this.getTokensFromSchema(this.schema)
        }
    },
    methods: {
        // Parses a given rule. Checks if it meets the set condition_group and parses the actions if it does
        parseRule(rule, schema) {
            if (
                rule.condition_group !== undefined
                &&
                !this.parseConditionGroup(rule.condition_group[0]))
            {

                return this.parseActions(schema, false, rule.actions);
            }
            else {
                return this.parseActions(schema, true, rule.actions);
            }
        },

        // Checks if the conditions within a condition group are
        //  A: both true if method = 'AND'
        //  B: one of the conditions is true if method='OR'
        parseConditionGroup(conditionGroup) {
            var index;
            if (conditionGroup.method === 'OR') {
                for (index in conditionGroup.conditions) {
                    let element = conditionGroup.conditions[index];
                    if (element.condition_group !== undefined)
                    {
                        if (this.parseConditionGroup(element.condition_group))
                        {
                            return true;
                        }
                    }
                    // Not a condition group but a normal condition
                    else if (this.parseCondition(element)) {
                        return true;
                    }
                }

                return false;
            }
            else { // Assume "AND" if the method isn't OR (fallback)
                for (index in conditionGroup.conditions) {
                    let element = conditionGroup.conditions[index];
                    // If it's a condition group
                    if (element.condition_group !== undefined)
                    {
                        if (!this.parseConditionGroup(element.condition_group)) {
                            return false;
                        }
                    }
                    // Not a condition group but a normal condition
                    else if (!this.parseCondition(element)) {
                        return false;
                    }
                }

                return true;
            }
        },

        // Parses the condition and returns the result of the evaluation (true/false)
        parseCondition(condition) {
            // Check if the condition is met
            //{dataName: 'shipping', operator: '=', value: 'fedex_overnight'},
            if (condition.dataName === undefined) {
                return true;
            }

            // Get the value of the element
            let element_value = '';
            if (this.values[condition.dataName] !== undefined) {
                element_value = this.values[condition.dataName];
            }

            // Get special values that are not inside the values object, like 'registration_phase'
            if (condition.dataName === 'registration_phase') {
                element_value = this.myForm.registration_phase;
            }

            // Load the element from the schema
            let element = this.getElementByName(this.schema, condition.dataName);
            // Check if element is of type 'map'
            if (element && element.elementType === 'map') {
                element_value = this.getMapElementValue(element_value, condition.datasourcePropertyName);
            }
            else if (element && element.elementType === 'search') {
                element_value = this.getSearchElementValue(element_value, condition.datasourcePropertyName);
            }

            if (Array.isArray(element_value)) {
                return this.evaluateMultiple(condition, element_value);
            }
            else {
                return this.evaluate(condition, element_value);
            }
        },

        // Takes the schema and conditions and executes the actions
        parseActions(schema, conditionsMet, actions) {
            for (var index in actions) {
                var action = actions[index]; // {dataName: 'shipping', method: 'show', value: 'fedex_overnight'}

                if (action.method === 'set') { // Set values if conditions are met
                    // If the conditions are we set the value
                    if (conditionsMet) {
                        // Replace tokens
                        let replacedActionValue = this.replaceTokens(action.value, this.tokensFromSchema, this.values);
                        // Set the value.
                        // Note: We set the value on 'this.myForm.values' because setting it in 'this.values' is not working because of an unknown reason.
                        //       Maybe because its a computed value.
                        this.$set(this.myForm.values, action.dataName, replacedActionValue);
                    }
                }
                else if (action.method === 'visually_hidden') {
                    // Add a property to the element to hide it visually
                    let element = this.getElementByName(schema, action.dataName);
                    if (element) {
                        if (conditionsMet) {
                            element.visuallyHidden = true;
                        }
                        else {
                            element.visuallyHidden = false;
                        }
                    }
                }
                else if (action.method === 'show') { // Show only if conditions are met.
                    if (!conditionsMet) {
                        // Conditions aren't met so we filter element out
                        schema = schema.filter(({ name }) => name !== action.dataName);
                    }
                }
                else { // Method is hide (or fallback to hide)
                    if (conditionsMet) {
                        // If the conditions are met we'll filter the element
                        schema = schema.filter(({ name }) => name !== action.dataName);
                    }
                }
            }

            return schema;
        },

        evaluate(condition, elementValue) {
            let conditionValue = null;
            if (condition.value) {
                conditionValue = condition.value;
            }

            // Convert elementValue and conditionValue to number when needed
            elementValue = this.convertValue(condition, elementValue);
            conditionValue = this.convertValue(condition, conditionValue);

            // Further convert elementValue to map datasource value when needed
            elementValue = this.convertElementValue(condition, elementValue);

            switch (condition.operator) {
                case "=":
                    return (elementValue === conditionValue);
                case "!":
                    return (elementValue !== conditionValue);
                case ">":
                    return (elementValue > conditionValue);
                case ">=":
                    return (elementValue >= conditionValue);
                case "<":
                    return (elementValue < conditionValue);
                case "<=":
                    return (elementValue <= conditionValue);
                case "empty":
                    if (elementValue.length === 0) {
                        return true;
                    } else if (_.isBoolean(elementValue) && elementValue === false) {
                        return true;
                    }
                    return false;
                case "!empty":
                    if (_.isBoolean(elementValue)) {
                        if (elementValue === true) {
                            return true;
                        }
                    }
                    else if (elementValue.length !== 0) {
                        return true;
                    }

                    return false;
                case "contains":
                    return (elementValue === conditionValue);
                case "!contains":
                    return (elementValue !== conditionValue);
                case "str_equals":
                    return elementValue.toLowerCase() === conditionValue.toLowerCase();
                case "str_starts_with":
                    return elementValue.toLowerCase().startsWith(conditionValue.toLowerCase());
                case "str_ends_with":
                    return elementValue.toLowerCase().endsWith(conditionValue.toLowerCase());
                case "str_includes":
                    return elementValue.toLowerCase().includes(conditionValue.toLowerCase());
                default:
                    this.$rollbar.error('Operator does not exist. JSON: ' + JSON.stringify(condition));
                    throw new Error('Operator "' + condition.operator + '" doesn\'t exist.');
            }
        },

        evaluateMultiple(condition, multipleValue) {
            switch (condition.operator) {
                case "=":
                    return (multipleValue.length === 1 && this.evaluate(condition, multipleValue[0]));
                case "!":
                    return (multipleValue.length !== 1 || this.evaluate(condition, multipleValue[0]));
                case "empty":
                    return this.evaluate(condition, multipleValue);
                case "!empty":
                    return this.evaluate(condition, multipleValue);
                case "contains":
                    for(let i = 0; i < multipleValue.length; i++) {
                        if (this.evaluate(condition, multipleValue[i]) === true) {
                            return true;
                        }
                    }

                    return false;
                case "!contains":
                    for(let i = 0; i < multipleValue.length; i++) {
                        if (this.evaluate(condition, multipleValue[i]) === false) {
                            return false;
                        }
                    }

                    return true;
                default:
                    throw new Error('Operator "' + condition.operator + '" doesn\'t exist for evaluate multiple.');
            }
        },
        convertValue(condition, value) {
            if (condition.dataName) {
                // Get the element type
                let element = this.getElementByName(this.schema, condition.dataName);
                // Elements that can have a number input
                let numberInputTypes = ['number','calculation'];
                // Convert string to number if it's a number condition
                if (element && element.elementType && numberInputTypes.includes(element.elementType)) {
                    return _.toNumber(value)
                }
            }
            // No conversion is needed. Return the original value.
            return value;
        },
        convertElementValue(condition, value) {
            if (condition.dataName) {
                // Get the element type
                let element = this.getElementByName(this.schema, condition.dataName);
                // Check if element is of type 'map' or 'search'
                if (element && element.elementType && element.elementType === 'map') {
                    return this.getMapElementValue(value, condition.datasourcePropertyName);
                }
                else if (element && element.elementType && element.elementType === 'search') {
                    return this.getSearchElementValue(value, condition.datasourcePropertyName);
                }
            }
            // No conversion is needed. Return the original value.
            return value;
        },
        getMapElementValue(element_value, propertyName) {
            // Check if propertyName is 'object.name'
            if (propertyName === 'object.name') {
                if (_.has(element_value, ['features', '0', 'properties', 'name'])) {
                    return _.get(element_value, ['features', '0', 'properties', 'name']);
                }
            }
            // Check if propertyName is 'object.status'
            else if (propertyName === 'object.status') {
                if (_.has(element_value, ['features', '0', 'properties', 'status'])) {
                    return _.get(element_value, ['features', '0', 'properties', 'status']);
                }
            }
            // Use the value of the data property of the selected map feature
            else if (_.has(element_value, ['features', '0', 'properties', 'data', propertyName])) {
                return _.get(element_value, ['features', '0', 'properties', 'data', propertyName]);
            }
            return element_value;
        },
        getSearchElementValue(element_value, propertyName) {
            // Check if propertyName is 'object.name'
            if (propertyName === 'object.name') {
                if (_.has(element_value, ['0', 'name'])) {
                    return _.get(element_value, ['0', 'name']);
                }
            }
            // Use the value of the data property of the selected datasource item
            else if (_.has(element_value, ['0', 'data', propertyName])) {
                return _.get(element_value, ['0', 'data', propertyName]);
            }
            return element_value;
        }

    }
};